<template>
  <section
    id="about"
    class="overflow-hidden primary"
  >
    <v-container>
      <v-row
        class="white"
      >
        <v-col
          class="pa-5 text-center "
          cols="12"
          md="12"
        >
          <base-title class="info--text">
            Unsere Philosophie
          </base-title>

          <base-text class="mb-5">
            Wir bieten unseren Kunden alles aus einer Hand.
            Ihnen steht ein zuverlässiger und kompetenter Partner zur Seite indem wir unsere
            Kunden vom Start bis zur Realisierung des Projekts unterstützen und entlasten.
            Wir stehen für Sicherheit durch strikte Überwachung und Einhaltung von Kosten,
            Terminen und Qualität.
          </base-text>
          <base-text class="mb-5">
            Der Kunde steht bei uns im Mittelpunkt – wir sind erst zufrieden, wenn der Kunde
            zufrieden ist.
          </base-text>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
  export default {
    data: () => ({
      skills: [
        {
          name: 'Web Design',
          value: 100,
        },
        {
          name: 'Web Development',
          value: 75,
        },
        {
          name: 'Web Support',
          value: 90,
        },
      ],
    }),
  }
</script>
